import useScrollPosition from '@react-hook/window-scroll'
import React from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

// import Logo from '../../assets/logo'

// MUI

import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined'

import { useActiveWeb3React } from '../../hooks/web3'
import { useETHBalances } from '../../state/wallet/hooks'
//import { BridgeMenu } from '../Menu/BridgeMenu'
//import { OnrampMenu } from '../Menu/OnrampMenu'
import { MobileMenu } from '../Menu/MobileMenu'

//import { ExternalLink } from '../../theme'

import { YellowCard } from '../Card'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
// import Modal from '../Modal'
// import UniBalanceContent from './UniBalanceContent'
import { ChainId } from 'constants/chains'
import DiffusionLogo from '../../assets/images/TBANNER.png'
import { ExternalLink } from 'theme/components'

const Logo = styled.img`
  width: 232px;

  //margin-left: 5px;
`

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  /* display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  position: relative;

  

  /* Background slide effect on scroll. */
  /* background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s; */

  /* ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 120px 1fr;

  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `} */

  .mui-icon {
    margin-right: 7.5px;
  }
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2400px;
  position: fixed;
  z-index: 200 !important;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  height: 75px;

  .bar {
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 0px 0px 0 0;
    background-color: rgba(0, 0, 0, 0.75);
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`

const HeaderLinks = styled(Row)`
  /* background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.darkTransparent2} 0%, ${theme.secondary1_10} 50%, ${theme.darkTransparent2} 100%);`};
  border: 1px solid #f9f3dd;
  box-shadow: 0 0 5px rgba(249, 243, 221, 0.2), 0 0 7px rgba(249, 243, 221, 0.2);
  margin-left: 4%;
  width: fit-content;
  padding: 4px;
  border-radius: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};

  margin-left: 0px; */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 10px;

  transform: translateY(3.1px) translateX(-12px);

  .mui-icon {
    margin-right: 7.5px;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.darkTransparent2} 0%, ${theme.secondary1_10} 50%, ${theme.darkTransparent2} 100%);`};
  border-radius: 22px !important;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  // border: 1px solid rgba(232, 24, 153, 0.25);
  //box-shadow: 0 0 5px rgba(249, 243, 221, 0.1), 0 0 7px rgba(249, 243, 221, 0.1);

  :focus {
    //  border: 1px solid blue;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const HideLarge = styled.span`
  @media screen and (min-width: 700px) {
    display: none !important;
  }
`

const NetworkCard = styled(YellowCard)`
  border-radius: 22px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  font-weight: 900;

  @media only screen and (max-width: 1150px) {
    display: none;
  }

  font-size: 0.975rem;
  border-radius: 8px;
  //  border: 2px solid #fff;

  // background-color: #69b8e3;

  //   width: 75px;
  height: 37.5px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 2px;

  transition: all 0.25s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 0px;
    font-weight: 800;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        display: none;
  `}
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 22px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  // const [isBridgeOpen, setIsBridgeOpen] = useState(false)

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()

  // const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

  const scrollY = useScrollPosition()

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}

      <div className="bar">
        <HeaderRow>
          <Title href="https://dex.swaptaiko.xyz/#/swap">
            <Logo src={DiffusionLogo} />
          </Title>

          <HeaderLinks>
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              <SwapHorizIcon className="mui-icon" />
              {t('Swap')}
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/increase') ||
                pathname.startsWith('/find')
              }
            >
              <WaterDropOutlinedIcon className="mui-icon" />
              {t('pool')}
            </StyledNavLink>
          </HeaderLinks>
        </HeaderRow>
      </div>

      <HideSmall></HideSmall>
      <HeaderControls>
        <HeaderElement>
          <HideSmall></HideSmall>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} <span style={{ color: '#f9f3dd' }}>ETH</span>
              </BalanceText>
            ) : null}

            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <HideLarge>
            <MobileMenu />
          </HideLarge>
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
