import React from 'react'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { darken } from 'polished'
import { usePopper } from 'react-popper'

import { NavLink, useLocation } from 'react-router-dom'
import { BridgeMenu } from './BridgeMenu'
import { OnrampMenu } from './OnrampMenu'
import { useTranslation } from 'react-i18next'

// MUI

import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined'

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 0px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;

  &.${activeClassName} {
    border-radius: 0px;
    font-weight: 800;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledBridgeButton = styled.div<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 35px;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.darkTransparent2} 0%, ${theme.secondary1_10} 50%, ${theme.darkTransparent2} 100%);`};
  //  border: 1px solid #f9f3dd;

  ${({ isActive }) =>
    isActive &&
    css`
      border-radius: 8px;
      color: ${({ theme }) => theme.text3};
      background-color: ${({ theme }) => theme.primaryTransparent};
    `}

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  transition: all 0.25s;

  font-size: 0.975rem;

  font-weight: 400;
  color: #fff;
  text-decoration: none;

  border-radius: 22px;

  background-color: rgba(232, 24, 153, 0.25);

  height: 37.5px;
  padding: 0px 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none !important;
  &:hover {
    background-color: rgba(232, 24, 153, 0.5);
    transform: scale(1) !important;
  }
`

const Menu = styled.div`
  min-width: 8.125rem;
  background: #10151b;
  // box-shadow: 0 0 5px rgba(249, 243, 221, 0.2), 0 0 7px rgba(249, 243, 221, 0.2);
  border: 1px solid #f9f3dd;
  border-radius: 22px;
  // backdrop-filter: blur(4px);
  z-index: 100;
  opacity: 100%;
  .mui-icon {
    margin-right: 7.5px;
  }

  margin-bottom: 15px;
`

export function MobileMenu() {
  const node = useRef<HTMLDivElement>()
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen((open) => !open), [setOpen])
  useOnClickOutside(node, open ? toggle : undefined)
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <div ref={node as any}>
      <StyledBridgeButton onClick={toggle} isActive={open} ref={setReferenceElement as any}>
        {location.pathname.startsWith('/swap') && <SwapHorizIcon className="mui-icon" />}
        {location.pathname.startsWith('/pool') && <WaterDropOutlinedIcon className="mui-icon" />}
        {location.pathname.startsWith('/add') && <WaterDropOutlinedIcon className="mui-icon" />}
        {location.pathname.startsWith('/remove') && <WaterDropOutlinedIcon className="mui-icon" />}

        {location.pathname.startsWith('/swap')
          ? 'Swap'
          : location.pathname.startsWith('/pool')
          ? 'Pool'
          : location.pathname.startsWith('/farm')
          ? 'Farm'
          : location.pathname.startsWith('/add')
          ? 'Pool'
          : location.pathname.startsWith('/remove')
          ? 'Pool'
          : 'Pool'}
      </StyledBridgeButton>

      {open && (
        <Menu ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            <SwapHorizIcon className="mui-icon" />
            {t('swap')}
          </StyledNavLink>

          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/increase') ||
              pathname.startsWith('/find')
            }
          >
            <WaterDropOutlinedIcon className="mui-icon" />
            {t('pool')}
          </StyledNavLink>

          <BridgeMenu />
          <OnrampMenu />
        </Menu>
      )}
    </div>
  )
}
